var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 5 of 5 ")
      ]),
      _c("gov-back-link", { attrs: { to: { name: "register-new-step4" } } }, [
        _vm._v(" Back ")
      ]),
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Register new Organisation ")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    If you are a smaller group or activity that isn't directly run by an\n    organisation, you can enter details about your service/group below.\n  "
        )
      ]),
      _c(
        "ck-text-input",
        {
          attrs: {
            id: "name",
            label: "Organisation name",
            type: "text",
            error: _vm.errors.get(["organisation.name", "organisation.slug"])
          },
          model: {
            value: _vm.form.organisation.name,
            callback: function($$v) {
              _vm.$set(_vm.form.organisation, "name", $$v)
            },
            expression: "form.organisation.name"
          }
        },
        [
          _c(
            "gov-hint",
            { attrs: { slot: "hint", for: "name" }, slot: "hint" },
            [
              _vm._v(
                "\n      The name of your organisation must be unique. The URL of your page will\n      be: "
              ),
              _c("br"),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.appUri) +
                  "/organisations/" +
                  _vm._s(_vm.form.organisation.slug) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c("ck-wysiwyg-input", {
        attrs: {
          id: "description",
          maxlength: 3000,
          label: "Please provide a one-line summary of organisation",
          hint:
            "This should be a short line or two that summarises who the organisation is and will appear below the Organisation name on it's page.",
          error: _vm.errors.get("organisation.description")
        },
        model: {
          value: _vm.form.organisation.description,
          callback: function($$v) {
            _vm.$set(_vm.form.organisation, "description", $$v)
          },
          expression: "form.organisation.description"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "url",
          label: "Organisation website address",
          hint: "This must start with 'http://'' or 'https://''.",
          type: "url",
          error: _vm.errors.get("organisation.url")
        },
        model: {
          value: _vm.form.organisation.url,
          callback: function($$v) {
            _vm.$set(_vm.form.organisation, "url", $$v)
          },
          expression: "form.organisation.url"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "phone",
          label: "Public phone",
          type: "tel",
          error: _vm.errors.get("organisation.phone")
        },
        model: {
          value: _vm.form.organisation.phone,
          callback: function($$v) {
            _vm.$set(_vm.form.organisation, "phone", $$v)
          },
          expression: "form.organisation.phone"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "email",
          label: "Public email address",
          type: "email",
          error: _vm.errors.get("organisation.email")
        },
        model: {
          value: _vm.form.organisation.email,
          callback: function($$v) {
            _vm.$set(_vm.form.organisation, "email", $$v)
          },
          expression: "form.organisation.email"
        }
      }),
      _c(
        "gov-button",
        {
          attrs: { start: "", disabled: _vm.fieldsEmpty },
          on: {
            click: function($event) {
              return _vm.$emit("completed")
            }
          }
        },
        [_vm._v("\n    Next\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }